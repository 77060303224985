.sidebar_view
    right: 0px
    width: 100%
    top: 0rem
    position: fixed
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 2000
    bottom: 0px
    height: calc(100vh)
    -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2)
    -moz-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2)
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2)

    .sidebar-body
        width: 450px // pixel 2, 2 xl, and iphone 6+,7+,8+
        height: calc(100vh)
        background-color: #fff
        overflow-y: scroll
        right: 0px
        top: 0rem
        bottom: 0px
        padding: 1rem
        float: right

// Enter and leave animations can use different
// durations and timing functions.   
.slide-fade-enter-active
    transition: transform 0.5s

.slide-fade-leave-active
    transition: transform 0.5s

.slide-fade-enter
    transform: translate3d(100%, 0, 0)
    opacity: 0

.slide-fade-leave-to
    transform: translate3d(-100%, 0, 0)
    opacity: 0

.row-table
    border-top: 1px #efefef solid
    display: flex
    align-items: center
    &:last-child
        border-bottom: 1px #efefef solid
    &.selected-row
        background: #efefef
