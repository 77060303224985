a.help-link
    text-decoration: underline
    color: $elv-pink
    font-weight: 500


.el-dialog__body
    .container-fluid
        .row
            .col-12
                ul.rules-list
                    li
                        color: #000
                        margin-bottom: 10px
                        margin-top: 10px