.vue-tel-input {
  border-color: $elv-light-grey !important;
  border-radius: 0 !important;
}

.el-date-editor--date,
.has-dropdown-element {
  .el-input__inner {
    margin-bottom: 10px;
  }
}

// set darker disabled color
.el-input.is-disabled .el-input__inner {
  color: #3c3c3c !important;
  -webkit-text-fill-color: #000000 !important;
}


input:disabled{
  background-color: #F5F7FA;
  color: #3c3c3c !important;
  -webkit-text-fill-color: #000000 !important;
}

.tc-modal {
  > .el-dialog {
    width: 100%;

    button span {
      white-space: break-word;
    }
  }
}


// Resolve weird DOM for element UI
// This will make sure that the tabs
// are not weirdly placed by elementui
// .funds-req-select {
//   .el-select__tags {
//     top: 30%;
//   }
// }