// Plugins

// FontAwesome 5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';
@import "~element-ui/packages/theme-chalk/src/index";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Pages
@import 'pages/dashboard';

// Header Styles
.read-only,
.logged-in-as {
    margin: 0 !important;
    border-radius: 0 !important;
}


/////////////////
// BE App.scss

// Import styles
@import "~@coreui/coreui/scss/coreui";

// Fix inaccessible dropdown in .table-responsive dropdown buttons
.table-responsive {
    overflow-x: inherit;

    td:not(.btn-td) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
    }
}

// Fix mobile footer
.app-footer {
    flex: 0 0;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
}


/////////////////
// FE App.scss

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
