.btn-pink {
  @extend %btn-base;
  color: $elv-blue;
  background-color: $elv-pink;
  border-color: $elv-pink;


  &:hover {
    color: $elv-blue;
    text-decoration: none;
    background-color: $elv-pink;
    border-color: $elv-pink;
  }
}