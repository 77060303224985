@import 'fonts';
@import 'forms';
@import 'tables';
@import 'backgrounds';
@import 'sidebar';
@import 'email_collection';
@import 'decorations';
@import 'ie-warnings';
@import 'helper_modal';

.has-interaction,
.can-interact {
    cursor: pointer;
}


// Decorative element
.elv-progress-bar {
    .el-progress-bar {
        margin-right: 0;
        padding-right: 0;

        .el-progress-bar__outer {
            height: 10px !important;
            background-color: #fff;

            .el-progress-bar__inner {
                background-color: $elv-pink;
            }
        }
    }
}
