.application-card {
  padding: 40px;

  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
  }

  .app-signature {
    height: 200px !important;
    width: 100% !important;
  }

  @media only screen and (max-width: 420px) {
    padding: 10px;

    .app-signature {
      width: 220px !important;
    }
  }

  .app-card-decoration {
    position: absolute;
    top: 0;
    left: 15px;
    width: 60px;
    height: 4px;
    background-color: $elv-pink;
  }

  h1 {
    margin-top: 22px;
    margin-bottom: 20px;
    color: $elv-blue;
    font-family: $heading-font;
    font-weight: 700;
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  h2 {
    font-size: 24px;
  }

  .app-card-intro {
    margin-bottom: 40px;
    font-family: $body-font;
    font-weight: 300;
    font-size: 18px;
  }

  p {
    margin-bottom: 20px;
    font-family: $body-font;
    font-weight: 400;
    font-size: 16px;
  }

  ul {
    padding-left: 14px;
    
    li {
      color: $elv-pink;
    }
  }

  label {
    margin-bottom: 10px;
    font-family: $body-font;
    font-size: 14px;
    font-weight: 400;
    color: $elv-black;
  }

  input {
    margin-bottom: 30px;
  }

  .vue-tel-input {
    margin-bottom: 30px;

    input {
      margin-bottom: 0;
    }
  }

  // dropdown
  .el-select {
    .el-input {
      input {
        margin-bottom: 0px !important;
      }
    }

    &.margin-b-30 {
      margin-bottom: 30px !important;
    }
  }
  // checkboxes
  .el-checkbox {
    display: flex;

    &.is-checked {
      .el-checkbox__label {
        color: $elv-green;
      }
    }

    .el-checkbox__label {
      white-space: break-spaces;
    }

    .el-checkbox__input {
      &.is-checked {
        .el-checkbox__inner {
          background-color: #fff;
          border-color: $elv-green;

          &:after {
            top: 2px;
            height: 11px;
            left: 7px;
            border-color: $elv-green;
          }
        }
      }

      &.is-focus {
        .el-checkbox__inner {
          border-color: $elv-green;
        }
      }

      .el-checkbox__inner {
        height: 20px;
        width: 20px;
        border-radius: 0;

        &:hover {
          border-color: $elv-green;
        }
      }
    }
  }

  .el-input__prefix,
  .el-input__suffix {
    height: unset;
  }

  // Radios
  .el-radio {
    display: flex;

    .el-radio__label {
      padding-top: 3px;
      white-space: break-spaces;
    }

    .el-radio__input {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      height: 20px;
      min-height: 20px;
      width: 20px;
      min-width: 20px;
      border: none; // uh?
      border: 1px solid $elv-light-grey;
      border-radius: 30px;

      .el-radio__inner {
        border-color: transparent;
      }

      &.is-checked .el-radio__inner {
        color: $elv-medium-grey;
        border-color: $elv-green;
        background: $elv-green;

        &:after {
          height: 12px;
          width: 12px;
          border: none;
          background-color: $elv-green;
        }
      }

      &:hover {
        border-color: $elv-green;
      }
    }
  }

  .btn { // not ideal name
    margin-top: 20px;
  }

  .id-margin-belt {
    input {
      margin-bottom: 10px;
    }
  }

  .application-modal-body p {
    word-break: none;
  }
}