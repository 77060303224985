.icon-btn {
  padding: 8px;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  transition: $all-3;

  &:hover {
    box-shadow: $box-shadow-btn;
  }
}