.login-card {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 470px;
  border-radius: 5px;
  background-color: #fff;
  border: none;

  .card-body {
    padding: 42px 40px 38px;

    @media only screen and (max-width: 768px) {
      padding: 15px;
    }

    .checkbox {
      label {
        cursor: pointer;
      }

      input {
        margin-right: 16px;
      }
    }
  }

  .btn-success {
    padding: 10px 15px;
    font-weight: 700 !important;
  }
}