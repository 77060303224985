

$elv-blue:           #18206f;
$elv-green:          #8ea604;
$elv-pink:           #ff968d;
$elv-red:            #ff4e00;
$elv-red-10:         rgba(255, 78, 0, 0.1);


$elv-dark-white:     #f1f1f1;
$elv-bg-grey:        #f4f4f4;
$elv-light-grey:     #e3e3e3;
$elv-grey:           #898989;
$elv-medium-grey:    #808080;
$elv-dark-grey:      #4e4e4e;
$elv-slate:          #212121;
$elv-black:          #000;

$heading-font:       "aktiv-grotesk-extended", sans-serif;
$body-font:          "aktiv-grotesk", sans-serif;

/*******************************************************/



/***********/
/*  Fonts  */

/* <h1> */
$h1-font-size:        28px;
$h1-font-weight:      700;
$h1-font-family:      $body-font;

/* <h2> */
$h2-font-size:        20px;
$h2-font-weight:      700;
$h2-font-family:      $body-font;


/* <h3> */
$h3-font-size:        18px;
$h3-font-weight:      700;
$h3-font-family:      $body-font;


/* <p> */
$p-font-size:        14px;
$p-font-weight:      400;
$p-font-family:      $body-font;
$p-color:            $elv-slate;
/*********************************/



/*****************/
/*  Box shadows  */

$box-shadow-btn: 0 2px 4px 4px rgba(0, 0, 0, 0.1);


/*****************/
// Transitions
$all-3:   0.3s all ease-in-out;


/////////////////
// Old variables

// BE colours
$cyan: #17a2b8;
$info: $cyan;

// FE colours
$font-family-sans-serif: "Nunito", sans-serif;
