.internet-explorer-warning {
    margin-top: 15px;
    padding: 15px 0;
    flex-direction: column;
    background-color: red;
    border-radius: 15px;

    p,
    strong {
        color: #fff;
        font-size: 18px;
    }
}