.breadcrumb {
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #fff;

  a {
    &:hover {
      color: $elv-green;
      text-decoration: none;
    }
  }
}