input,
textarea,
select {
  border-color: $elv-light-grey !important;
  border-radius: 0 !important;
}

select {
  min-height: 40px;
  cursor: pointer;
}

.show-form-error {
  padding: 13px 10px;
  margin-bottom: 30px;
  width: 100%;
  background-color: $elv-red-10;
  
  p {
    text-align: center;
    color: $elv-red;
  }
}

.is-error {
  input, textarea, select {
    border-color: $elv-red !important;
  }
}

input.is-error, 
textarea.is-error, 
select.is-error {
  border-color: $elv-red !important;
}

p.is-error {
  color: $elv-red !important;
}

.show-input-required {
  input {
    border-color: $elv-red !important;
  }
}