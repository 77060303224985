@import 'button-blue';
@import 'button-green';
@import 'button-pink';
@import 'button-white';
@import 'button-white-green-text';
@import 'button-icon';

%btn-base {
  padding: 10px 15px;
  color: $elv-blue;
  font-weight: 700 !important;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  transition: $all-3;

  &:hover {
    box-shadow: $box-shadow-btn;
  }
}