.bg-light-grey {
  background-color: $elv-light-grey;
}

.bg-md-light-grey {
  background-color: $elv-bg-grey;
}

.bg-dark-white {
  background-color: $elv-dark-white;
}

.bg-grey {
  background-color: $elv-grey;
}

.bg-slate {
  background-color: $elv-slate;
}

.bg-white {
  background-color: #fff;
}

.bg-hover-white:hover {
  background-color: #fff !important;
}

a,
button {
  &.bg-hover-white:hover,
  &.bg-hover-white:focus {
    background-color: #fff !important;
    text-decoration: none;
  }
}

// Mystery code interferes with this
.bg-pink {
  background-color: $elv-pink !important;
}

.bg-transparent {
  background-color: transparent;
}

.bg-blue {
  background-color: $elv-blue !important;
}