.btn-green {
  @extend %btn-base;
  color: #fff;
  border-color: $elv-green;
  background-color: $elv-green;
}

.btn-green-text {
  @extend %btn-base;
  border: none !important;
  color: $elv-green !important;
  background-color: #fff !important;
}