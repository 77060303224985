%btn-override-base {
  color: $elv-blue;
  font-weight: 700 !important;
  font-size: 16px !important;
  border-radius: 5px !important;

  &:hover {
    box-shadow: $box-shadow-btn;
  }
}

.btn-success,
.btn-primary,
.el-button--primary {
  @extend %btn-override-base;
  color: #fff !important;
  background-color: $elv-green !important;
  border-color: $elv-green !important;

  &:hover {
    color: #fff !important;
    background-color: $elv-green !important;
    border-color: $elv-green !important;
  }
}

.el-button--danger,
.btn-danger {
  @extend %btn-override-base;
  color: #fff !important;
  background-color: $elv-red;
  border-color: $elv-red;

  &:hover {
    color: #fff !important;
    background-color: $elv-red;
    border-color: $elv-red;
  }
}

.mutable-mobile-width {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}