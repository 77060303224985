h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  color: $elv-blue;
}

h1 {
  font-family: $h1-font-family;
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;

  @media only screen and (max-width: 1400px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 16px;
  }
}

h2 {
  font-family: $h2-font-family;
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;

  @media only screen and (max-width: 320px) {
    font-size: 16px;
  }
}

h3,
h4,
h5,
h6 {
  font-family: $h3-font-family;
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;

  @media only screen and (max-width: 320px) {
    font-size: 16px;
  }
}


p,
strong,
b,
em,
i,
th,
td,
div,
li,
a {
  font-size: $p-font-size;
  font-family: $body-font;
  color: $p-color;

  @media only screen and (max-width: 1400px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 12px;
  }
}

.no-hover {
  &:hover {
    text-decoration: none;
  }
}

.table,
table {
  th {
    padding-bottom: 9px;
    font-family: $body-font;
    font-size: 12px;
    color: $elv-medium-grey;
    border-top: none;
    border-bottom: 1px solid $elv-light-grey !important;

    span {
      font-family: $body-font;
      font-size: 12px;
      color: $elv-medium-grey;
    }
  }
}

// In here because it was attached to the text element
.table,
tbody {
  tr:not(:last-child) {
    td {
      border-top: none;
      border-bottom: 1px solid $elv-dark-white !important;
    }
  }
}

label {
  margin-bottom: 10px;
  font-family: $body-font;
  font-size: 12px;
  color: $elv-medium-grey;
}

.font-heading {
  font-family: $heading-font !important;
}

.font-body {
  font-family: $body-font !important;
}


// Font sizes
.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px;
}

///////////////////////
// Colors
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-m-grey {
  color: $elv-medium-grey;
}

.text-d-grey {
  color: $elv-dark-grey;
}

.text-blue {
  color: $elv-blue;
}

.text-green {
  color: $elv-green;
}

.text-pink {
  color: $elv-pink;
}

.text-red {
  color: $elv-red;
}

.text-slate {
  color: $elv-slate;
}

.font-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}


// Font weights
.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700 !important;
}

.text-underline {
  text-decoration: underline !important;
}