.sidebar-nav {
  background-color: $elv-blue;

  .nav-title {
    font-weight: 400;
  }

  .nav-link {
    transition: $all-3;
    &:hover {
      background-color: $elv-pink;
    }

    &.active {
      background-color: $elv-pink;
      color: #fff;

      &:hover .nav-icon{
        background-color: $elv-pink;
        color: #fff;
      }
      .nav-icon{
        color: #fff;
      }
    }

    .nav-icon {
      color: #fff;
    }
  }
}

.sidebar-minimizer {
  background-color: $elv-blue !important;
}

.sidebar .sidebar-minimizer {
  &:hover::before {
    background-image: unset;
  }
  &::before {
    background-image: unset;
  }
}

.sidebar-minimized .sidebar {
  .sidebar-nav {
    padding-top: 50px;
  }

  .sidebar-minimizer {
    z-index: 1;
    top: 55px;
  }
}