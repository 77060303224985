@import 'sidenav';
@import 'breadcrumbs';

.application-navbar {
  position: relative;

  .nav-link {
    color: $elv-medium-grey !important;
    font-family: $heading-font;
    font-weight: 700;
  }

  .center-navbar {
    top: 14px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 128px;
  }
}

@media (min-width: 992px) {
  .application-navbar {
    .navbar-collapse {
      flex-basis: unset;
      flex-grow: unset;
    }
  }
}
